import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React, { FC } from 'react'

import { TeamMemberList } from '@/components/about-us/TeamMemberList'
import { PageCover } from '@/components/common/PageCover'
import { SEO } from '@/components/SEO'

const query = graphql`
  query {
    coverImage: strapiCoverImages(slug: { eq: "our-team" }) {
      cover {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    teamMemberGroups: allStrapiTeamMemberGroups(
      sort: { fields: [id], order: ASC }
    ) {
      nodes {
        name
        team_members {
          name
          role
          bio
          photo {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`

const OurTeamPage: FC = () => {
  const data = useStaticQuery(query)
  const image = getImage(data.coverImage.cover.localFile)

  return (
    <>
      <SEO title="Our Team" />
      <PageCover image={image} title="Our Team" />
      <TeamMemberList teamMemberGroups={data.teamMemberGroups.nodes} />
    </>
  )
}

export default OurTeamPage
