import { makeStyles } from '@material-ui/core'
import React, { FC, useState } from 'react'

import { PageContainer } from '@/components/common/PageContainer'
import { SectionTitle } from '@/components/common/SectionTitle'
import { TeamMember, TeamMemberGroup } from '@/models/TeamMember'

import { TeamMemberCard } from './TeamMemberCard'

type TeamMemberListProps = {
  teamMemberGroups: TeamMemberGroup[]
}

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  group: {
    '&:not(:last-child)': {
      marginBottom: spacing(16),
    },
    [breakpoints.down('xs')]: {
      '&:not(:last-child)': {
        marginBottom: spacing(4),
      },
    },
  },
  groupTitle: {
    textAlign: 'left',
    marginBottom: spacing(6),
    [breakpoints.down('xs')]: {
      marginBottom: spacing(6),
    },
  },
  cards: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    alignContent: 'flex-start',
    flexWrap: 'wrap',
    margin: spacing(0, -2, -4),
    '& > div': {
      margin: spacing(4, 2),
    },
    [breakpoints.down('xs')]: {
      flexDirection: 'column',
      margin: 0,
      '& > div': {
        margin: spacing(0, 0, 6),
      },
    },
  },
}))
export const TeamMemberList: FC<TeamMemberListProps> = ({
  teamMemberGroups,
}) => {
  const classes = useStyles()
  const [openFormKey, setOpenFormKey] = useState<number | null>(null)

  return (
    <PageContainer>
      {teamMemberGroups.map(({ name, team_members }) => (
        <div key={name} className={classes.group}>
          <SectionTitle className={classes.groupTitle}>{name}</SectionTitle>
          <div className={classes.cards}>
            {team_members.map((teamMember: TeamMember) => (
              <TeamMemberCard key={teamMember.name} teamMember={teamMember} />
            ))}
          </div>
        </div>
      ))}
    </PageContainer>
  )
}
