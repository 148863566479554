import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React, { ComponentPropsWithoutRef, FC } from 'react'

const useStyles = makeStyles(({ breakpoints, palette, typography }) => ({
  sectionTitle: {
    margin: 0,
    fontSize: '2.125rem',
    fontWeight: typography.fontWeightBold,
    letterSpacing: '0.036765em',
    color: palette.primary.main,
    textAlign: 'center',
    [breakpoints.down('sm')]: {
      fontSize: '1.375rem',
      letterSpacing: '0.03125em',
    },
    [breakpoints.down('xs')]: {
      fontSize: '1.125rem',
    },
  },
}))

type SectionTitleProps = ComponentPropsWithoutRef<'div'>

export const SectionTitle: FC<SectionTitleProps> = ({
  className,
  children,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <h1 className={clsx(classes.sectionTitle, className)} {...rest}>
      {children}
    </h1>
  )
}
