import { makeStyles } from '@material-ui/core'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React, { FC } from 'react'

import avatar from '@/assets/jpg/avatar.jpg'
import { TeamMember } from '@/models/TeamMember'

const useStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    root: {
      position: 'relative',
      flex: 'none',
      minWidth: 300,
      width: `calc((100% - ${spacing(12)}px) / 3)`,
      marginTop: spacing(3),
      letterSpacing: '0.03125em',
      color: palette.text.primary,
      textAlign: 'center',
      [breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    title: {
      position: 'absolute',
      top: spacing(-3),
      left: '50%',
      transform: 'translateX(-50%)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '83.333333%',
      height: 76,
      backgroundColor: palette.secondary.main,
      fontSize: '1.375rem',
      fontWeight: typography.fontWeightBold,
      [breakpoints.down('xs')]: {
        height: 40,
        fontSize: '0.875rem',
      },
    },
    image: {
      width: '100%',
      height: '352px',
      display: 'flex',
    },
    content: {
      width: '100%',
      height: 'calc(100% - 400px)',
      padding: spacing(6, 4, 4),
      backgroundColor: 'rgba(230, 230, 230, 0.4)',
      [breakpoints.down('xs')]: {
        padding: spacing(3, 2, 2),
      },
    },
    subtitle: {
      margin: spacing(2, 0),
      fontSize: '1.25rem',
      [breakpoints.down('xs')]: {
        margin: spacing(1, 0),
        fontSize: '0.875rem',
      },
    },
    description: {
      marginTop: spacing(4),
      fontSize: '0.875rem',
      lineHeight: 1.714,
      [breakpoints.down('xs')]: {
        marginTop: spacing(2),
        fontSize: '0.75rem',
      },
    },
  })
)

type TeamMemberCardProps = {
  teamMember: TeamMember
}

export const TeamMemberCard: FC<TeamMemberCardProps> = ({ teamMember }) => {
  const classes = useStyles()
  const image = getImage(teamMember.photo?.localFile)

  return (
    <div className={classes.root}>
      {image ? (
        <GatsbyImage
          className={classes.image}
          image={image}
          alt={teamMember.name}
        />
      ) : (
        <img className={classes.image} src={avatar} alt="Team member" />
      )}
      <div className={classes.title}>{teamMember.name}</div>
      <div className={classes.content}>
        {teamMember.role && (
          <div className={classes.subtitle}>{teamMember.role}</div>
        )}
        <p className={classes.description}>{teamMember.bio}</p>
      </div>
    </div>
  )
}
